var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page-home"},[_c('head-nav'),_c('div',{staticClass:"idx-ban"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.swiperLists),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('router-link',{attrs:{"to":item.url}},[_c('img',{staticClass:"img",attrs:{"src":item.img}})])],1)}),0),_c('div',{staticClass:"swiper-pagination"})])]),_c('div',{staticClass:"idx-s1"},[_c('div',{staticClass:"auto wrap flex flex-pack-justify flex-w"},[_c('div',{staticClass:"idx-cg flex-1"},[_c('div',{staticClass:"idx-tt"},[_c('span',{staticClass:"bt"},[_vm._v("学术成果")]),_c('router-link',{staticClass:"more fr",attrs:{"to":"/xueshuchengguo"}},[_c('i',{staticClass:"iconfont yt-more"})])],1),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"top-sec flex flex-pack-justify"},[_c('div',{staticClass:"slidebox"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.kjcgList2),function(item,index){return (index < 3)?_c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"item zoomimg"},[_c('router-link',{attrs:{"to":{
                          path: '/xueshuchengguo',
                          query: {
                            itemId: item.ItemId,
                            rootId: _vm.rootIdNum,
                            supItemId: item.ParentId,
                            typeCode: item.TypeCode,
                          },
                        }}},[(index == '0')?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/images/idx_02_01.jpg")}}):_vm._e(),(index == '1')?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/images/idx_02_02.jpg")}}):_vm._e(),(index == '2')?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/images/idx_02_03.jpg")}}):_vm._e(),_c('h6',{staticClass:"title ellipsis"},[_vm._v(_vm._s(item.ItemName))])])],1)]):_vm._e()}),0),_c('div',{staticClass:"swiper-pagination"})])]),_c('ul',{staticClass:"list-t"},_vm._l((_vm.kjcgList2),function(item,index){return (index > 2 && index < 5)?_c('li',{key:index,staticClass:"item"},[_c('router-link',{attrs:{"to":{
                    path: '/kejichengguo',
                    query: {
                      itemId: item.ItemId,
                      rootId: _vm.rootIdNum,
                      supItemId: item.ParentId,
                      typeCode: item.TypeCode,
                    },
                  }}},[_c('div',{staticClass:"imgbox zoomimg"},[(index == '3')?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/images/idx_03_02.jpg")}}):_vm._e(),(index == '4')?_c('img',{staticClass:"img",attrs:{"src":require("../../assets/images/idx_03.jpg")}}):_vm._e(),_c('div',{staticClass:"shade"},[_c('h6',{staticClass:"title clamp"},[_vm._v(_vm._s(item.ItemName))]),_c('p',{staticClass:"date"},[_vm._v(_vm._s(item.SF2))])])])])],1):_vm._e()}),0)]),_c('ul',{staticClass:"list-b flex"},_vm._l((_vm.kjcgList3),function(item,index){return (index < 3)?_c('li',{key:index,staticClass:"item img_traslate"},[_c('router-link',{attrs:{"to":{
                  path: '/kejichengguo',
                  query: {
                    itemId: item.ItemId,
                    rootId: _vm.rootIdNum,
                    supItemId: item.ParentId,
                    typeCode: item.TypeCode,
                  },
                }}},[_c('div',{staticClass:"conbx"},[_c('p',{staticClass:"title t-omit fwb"},[_vm._v(_vm._s(item.ItemName))]),_c('p',{staticClass:"text t-omits"},[_c('span',[_vm._v("参编单位:")]),_vm._v(_vm._s(item.SF5)+" ")])]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.SF1))])])],1):_vm._e()}),0)])]),_c('div',{staticClass:"idx-cx"},[_c('div',{staticClass:"idx-tt"},[_c('span',{staticClass:"bt"},[_vm._v("核心技术")]),_c('router-link',{staticClass:"more fr",attrs:{"to":"/hexinjishu"}},[_c('i',{staticClass:"iconfont yt-more"})])],1),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"hotbox"},[_c('a',{attrs:{"target":"_blank","href":"../../assets/images/video1.mp4"}},[_c('div',{staticClass:"videobox"},[_c('video',{staticClass:"img",attrs:{"width":"100%","autoplay":"","loop":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../../assets/images/video1.mp4"),"type":"video/mp4"}})])])])]),_c('div',{staticClass:"top-sec"},[_c('ul',{staticClass:"list"},_vm._l((_vm.list4),function(item,index){return (index < 7)?_c('li',{key:index,staticClass:"item"},[_c('router-link',{staticClass:"flexLrTc",attrs:{"to":{
                    path: '/kejichuangxin',
                    query: {
                      itemId: item.ItemId,
                      rootId: _vm.rootIdNum,
                      supItemId: item.ParentId,
                      typeCode: item.TypeCode,
                    },
                  }}},[_c('h6',{staticClass:"title clamp ellipsis"},[_vm._v(_vm._s(item.ItemName))])])],1):_vm._e()}),0)])])])])]),_c('div',{staticClass:"idx-s3 bg-white"},[_c('div',{staticClass:"auto wrap flex flex-pack-justify flex-w"},[_c('div',{staticClass:"idx-xs flex-1"},[_c('div',{staticClass:"idx-tt flexLrTc"},[_c('span',{staticClass:"bt"},[_vm._v("学术活动")]),_c('router-link',{staticClass:"more",attrs:{"to":"/xueshuhuodong"}},[_c('i',{staticClass:"iconfont yt-more"})])],1),_c('div',{staticClass:"detail flex flex-w flex-pack-justify bg-white"},[_c('ul',{staticClass:"list"},_vm._l((_vm.list5),function(item,index){return (index < 4)?_c('li',{key:index,staticClass:"item"},[_c('router-link',{attrs:{"to":{
                  path: '/xueshuhuodong',
                  query: {
                    itemId: item.ItemId,
                    rootId: _vm.rootIdNum,
                    supItemId: item.ParentId,
                    typeCode: item.TypeCode,
                  },
                }}},[_c('div',{staticClass:"infobox"},[_c('h6',{staticClass:"title ellipsis"},[_vm._v(" "+_vm._s(item.ItemName)+" ")]),_c('p',{staticStyle:{"font-size":"12px","color":"#999","margin-top":"10px"}},[_vm._v(" "+_vm._s(_vm.common.dateFormat("YYYY-mm-dd", item.CrDate))+" ")])]),_c('div',{staticClass:"txt clamp",domProps:{"innerHTML":_vm._s(_vm.delHtmlTag(item.ItemContent))}})])],1):_vm._e()}),0),_vm._m(0)])]),_c('div',{staticClass:"idx-huod"},[_c('div',{staticClass:"idx-tt"},[_c('span',{staticClass:"bt"},[_vm._v("社会活动")]),_c('router-link',{staticClass:"more fr",attrs:{"to":"/shehuihuodong"}},[_c('i',{staticClass:"iconfont yt-more"})])],1),_c('ul',{staticClass:"list bg-white"},_vm._l((_vm.list6),function(item,index){return (index < 2)?_c('li',{key:index,staticClass:"item"},[_c('router-link',{attrs:{"to":{
                path: '/shehuihuodong',
                query: {
                  itemId: item.ItemId,
                  rootId: _vm.rootIdNum,
                  supItemId: item.ParentId,
                  typeCode: item.TypeCode,
                },
              }}},[_c('div',{staticClass:"imgbox zoomimg"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/images/idx_03_02.jpg")}}),_c('div',{staticClass:"shade"},[_c('h6',{staticClass:"title ellipsis"},[_vm._v(" "+_vm._s(item.ItemName)+" ")])])])]),_c('div',{staticClass:"txt clamp2",domProps:{"innerHTML":_vm._s(_vm.delHtmlTag(item.ItemContent))}}),_c('div',{staticClass:"date"})],1):_vm._e()}),0)])])]),_c('div',{staticClass:"idx-reward bg-white"},[_c('div',{staticClass:"auto"},[_c('div',{staticClass:"idx-tt"},[_c('span',{staticClass:"bt"},[_vm._v("荣誉奖励")]),_c('router-link',{staticClass:"more fr",attrs:{"to":"/rongyujiangli"}},[_c('i',{staticClass:"iconfont yt-more"})])],1),_c('ul',{staticClass:"team-list flex flex-w"},_vm._l((_vm.list7),function(item,index){return (index < 3)?_c('li',{key:index,staticClass:"item"},[_c('router-link',{attrs:{"to":{
              path: '/rongyujiangli',
              query: {
                itemId: item.ItemId,
                rootId: _vm.rootIdNum,
                supItemId: item.ParentId,
                typeCode: item.TypeCode,
              },
            }}},[_c('div',{staticClass:"imgbox zoomimg"},[_c('div',{staticClass:"img",style:({ backgroundImage: 'url(' + _vm.kjjlImg[index] + ')' })})]),_c('h6',{staticClass:"title clamp1"},[_vm._v(" "+_vm._s(item.ItemName)+" ")]),_c('div',{staticClass:"bot flex flex-pack-justify"},[_c('div',{staticClass:"fz ellipsis"},[_c('i',{staticClass:"iconfont yt-jiangpai"}),_vm._v(_vm._s(item.SF1)+" ")]),_c('div',{staticClass:"fz ellipsis"},[_c('i',{staticClass:"iconfont yt-time"}),_vm._v(_vm._s(item.SF2)+" ")])])])],1):_vm._e()}),0)])]),_c('div',{staticClass:"idx-s4"},[_c('div',{staticClass:"auto wrap flex flex-pack-justify flex-w"},[_c('div',{staticClass:"idx-zx"},[_c('div',{staticClass:"idx-tt"},[_c('span',{staticClass:"bt"},[_vm._v("工程咨询")]),_c('router-link',{staticClass:"more fr",attrs:{"to":"/gongchengzixun"}},[_c('i',{staticClass:"iconfont yt-more"})])],1),_c('ul',{staticClass:"list"},_vm._l((_vm.list8),function(item,index){return (index < 4)?_c('li',{key:index,staticClass:"item"},[_c('router-link',{staticClass:"title ellipsis",attrs:{"to":{
                path: '/gongchengzixun',
                query: {
                  itemId: item.ItemId,
                  rootId: _vm.rootIdNum,
                  supItemId: item.ParentId,
                  typeCode: item.TypeCode,
                },
              }}},[_vm._v(_vm._s(item.ItemName))]),_c('div',{staticClass:"date"},[_c('span',[_vm._v(_vm._s(item.SF5 ? _vm.common.dateFormat("YYYY-mm-dd", item.SF5) : _vm.common.dateFormat("YYYY-mm-dd", item.CrDate)))])])],1):_vm._e()}),0)]),_c('div',{staticClass:"idx-rc"},[_c('div',{staticClass:"idx-tt"},[_c('span',{staticClass:"bt"},[_vm._v("人才培养")]),_c('router-link',{staticClass:"more fr",attrs:{"to":"/rencaipeiyang"}},[_c('i',{staticClass:"iconfont yt-more"})])],1),_c('ul',{staticClass:"list"},_vm._l((_vm.list9),function(item,index){return (index < 4)?_c('li',{key:index,staticClass:"item"},[_c('router-link',{staticClass:"flex",attrs:{"to":{
                path: '/rencaipeiyang',
                query: {
                  itemId: item.ItemId,
                  rootId: _vm.rootIdNum,
                  supItemId: item.ParentId,
                  typeCode: item.TypeCode,
                },
              }}},[_c('div',{staticClass:"imgbox zoomimg"},[_c('img',{staticClass:"img",attrs:{"src":item.Thum == null || item.Thum[0] == undefined
                      ? _vm.naDefault
                      : _vm.apiUrl +
                        '/Item/PreviewFile?fileName=' +
                        encodeURIComponent(item.Thum[0])}})]),_c('div',{staticClass:"info"},[_c('h6',{staticClass:"title ellipsis"},[_vm._v(_vm._s(item.ItemName))]),(item.SF2)?_c('p',{staticClass:"date"},[_vm._v("性别："+_vm._s(item.SF2))]):_vm._e(),(item.SF3)?_c('p',{staticClass:"date"},[_vm._v("出生："+_vm._s(item.SF3))]):_vm._e(),(item.SF9)?_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(item.SF9)),(item.SF8)?_c('span',[_vm._v("("+_vm._s(item.SF8)+")")]):_vm._e()]):_vm._e(),_c('em',{staticClass:"arrow"})])])],1):_vm._e()}),0)])])]),_c('foot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgbox"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/images/idx_05.jpg")}})])
}]

export { render, staticRenderFns }