<template>
  <div class="page page-home">
    <head-nav />
    <div class="idx-ban">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiperLists"
            :key="index"
          >
            <router-link :to="item.url">
              <img :src="item.img" class="img" />
            </router-link>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="idx-s1">
      <div class="auto wrap flex flex-pack-justify flex-w">
        <div class="idx-cg flex-1">
          <div class="idx-tt">
            <span class="bt">学术成果</span>
            <router-link to="/xueshuchengguo" class="more fr"
              ><i class="iconfont yt-more"></i
            ></router-link>
          </div>
          <div class="detail">
            <div class="top-sec flex flex-pack-justify">
              <div class="slidebox">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      v-for="(item, index) in kjcgList2"
                      :key="index"
                      v-if="index < 3"
                    >
                      <div class="item zoomimg">
                        <router-link
                          :to="{
                            path: '/xueshuchengguo',
                            query: {
                              itemId: item.ItemId,
                              rootId: rootIdNum,
                              supItemId: item.ParentId,
                              typeCode: item.TypeCode,
                            },
                          }"
                        >
                          <img
                            v-if="index == '0'"
                            src="../../assets/images/idx_02_01.jpg"
                            class="img"
                          />
                          <img
                            v-if="index == '1'"
                            src="../../assets/images/idx_02_02.jpg"
                            class="img"
                          />
                          <img
                            v-if="index == '2'"
                            src="../../assets/images/idx_02_03.jpg"
                            class="img"
                          />
                          <h6 class="title ellipsis">{{ item.ItemName }}</h6>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
              </div>
              <ul class="list-t">
                <li
                  class="item"
                  v-for="(item, index) in kjcgList2"
                  :key="index"
                  v-if="index > 2 && index < 5"
                >
                  <router-link
                    :to="{
                      path: '/kejichengguo',
                      query: {
                        itemId: item.ItemId,
                        rootId: rootIdNum,
                        supItemId: item.ParentId,
                        typeCode: item.TypeCode,
                      },
                    }"
                  >
                    <div class="imgbox zoomimg">
                      <img
                        v-if="index == '3'"
                        src="../../assets/images/idx_03_02.jpg"
                        class="img"
                      />
                      <img
                        v-if="index == '4'"
                        src="../../assets/images/idx_03.jpg"
                        class="img"
                      />
                      <div class="shade">
                        <h6 class="title clamp">{{ item.ItemName }}</h6>
                        <p class="date">{{ item.SF2 }}</p>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <ul class="list-b flex">
              <li
                class="item img_traslate"
                v-for="(item, index) in kjcgList3"
                :key="index"
                v-if="index < 3"
              >
                <router-link
                  :to="{
                    path: '/kejichengguo',
                    query: {
                      itemId: item.ItemId,
                      rootId: rootIdNum,
                      supItemId: item.ParentId,
                      typeCode: item.TypeCode,
                    },
                  }"
                >
                  <div class="conbx">
                    <p class="title t-omit fwb">{{ item.ItemName }}</p>
                    <p class="text t-omits">
                      <span>参编单位:</span>{{ item.SF5 }}
                    </p>
                  </div>
                  <div class="date">{{ item.SF1 }}</div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-------------------------->
        <div class="idx-cx">
          <div class="idx-tt">
            <span class="bt">核心技术</span>
            <router-link to="/hexinjishu" class="more fr"
              ><i class="iconfont yt-more"></i
            ></router-link>
          </div>
          <div class="detail">
            <div class="hotbox">
              <a target="_blank" href="../../assets/images/video1.mp4">
                <div class="videobox">
                  <video class="img" width="100%" autoplay loop muted>
                    <source
                      src="../../assets/images/video1.mp4"
                      type="video/mp4"
                    />
                  </video>
                  <!-- <img src="../../assets/images/idx_03.jpg" class="img" /> -->
                </div>
              </a>
            </div>
            <div class="top-sec">
              <ul class="list">
                <li
                  class="item"
                  v-for="(item, index) in list4"
                  :key="index"
                  v-if="index < 7"
                >
                  <router-link
                    class="flexLrTc"
                    :to="{
                      path: '/kejichuangxin',
                      query: {
                        itemId: item.ItemId,
                        rootId: rootIdNum,
                        supItemId: item.ParentId,
                        typeCode: item.TypeCode,
                      },
                    }"
                  >
                    <h6 class="title clamp ellipsis">{{ item.ItemName }}</h6>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-------------------------->
      </div>
    </div>
    <!------------------------------->

    <div class="idx-s3 bg-white">
      <div class="auto wrap flex flex-pack-justify flex-w">
        <div class="idx-xs flex-1">
          <div class="idx-tt flexLrTc">
            <span class="bt">学术活动</span>
            <!-- <router-link to="/xueshujiaoliu" class="more fr"><i class="icon-more"></i></router-link> -->

            <router-link to="/xueshuhuodong" class="more"
              ><i class="iconfont yt-more"></i
            ></router-link>
          </div>
          <div class="detail flex flex-w flex-pack-justify bg-white">
            <ul class="list">
              <li
                class="item"
                v-for="(item, index) in list5"
                :key="index"
                v-if="index < 4"
              >
                <router-link
                  :to="{
                    path: '/xueshuhuodong',
                    query: {
                      itemId: item.ItemId,
                      rootId: rootIdNum,
                      supItemId: item.ParentId,
                      typeCode: item.TypeCode,
                    },
                  }"
                >
                  <div class="infobox">
                    <h6 class="title ellipsis">
                      {{ item.ItemName }}
                    </h6>
                    <p style="font-size: 12px; color: #999; margin-top: 10px">
                      {{ common.dateFormat("YYYY-mm-dd", item.CrDate) }}
                    </p>
                  </div>
                  <div
                    class="txt clamp"
                    v-html="delHtmlTag(item.ItemContent)"
                  ></div>
                </router-link>
              </li>
            </ul>
            <div class="imgbox">
              <img src="../../assets/images/idx_05.jpg" class="img" />
            </div>
          </div>
        </div>
        <div class="idx-huod">
          <div class="idx-tt">
            <span class="bt">社会活动</span>
            <router-link to="/shehuihuodong" class="more fr"
              ><i class="iconfont yt-more"></i
            ></router-link>
          </div>
          <ul class="list bg-white">
            <li
              class="item"
              v-for="(item, index) in list6"
              :key="index"
              v-if="index < 2"
            >
              <router-link
                :to="{
                  path: '/shehuihuodong',
                  query: {
                    itemId: item.ItemId,
                    rootId: rootIdNum,
                    supItemId: item.ParentId,
                    typeCode: item.TypeCode,
                  },
                }"
              >
                <div class="imgbox zoomimg">
                  <img src="../../assets/images/idx_03_02.jpg" class="img" />
                  <div class="shade">
                    <h6 class="title ellipsis">
                      {{ item.ItemName }}
                    </h6>
                  </div>
                </div>
              </router-link>
              <div
                class="txt clamp2"
                v-html="delHtmlTag(item.ItemContent)"
              ></div>
              <div class="date"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!------------------------------->

    <div class="idx-reward bg-white">
      <div class="auto">
        <div class="idx-tt">
          <span class="bt">荣誉奖励</span>
          <router-link to="/rongyujiangli" class="more fr"
            ><i class="iconfont yt-more"></i
          ></router-link>
        </div>
        <ul class="team-list flex flex-w">
          <li
            class="item"
            v-for="(item, index) in list7"
            :key="index"
            v-if="index < 3"
          >
            <router-link
              :to="{
                path: '/rongyujiangli',
                query: {
                  itemId: item.ItemId,
                  rootId: rootIdNum,
                  supItemId: item.ParentId,
                  typeCode: item.TypeCode,
                },
              }"
            >
              <div class="imgbox zoomimg">
                <div
                  class="img"
                  :style="{ backgroundImage: 'url(' + kjjlImg[index] + ')' }"
                ></div>
                <!-- <img :src="kjjlImg[index]" class="img" /> -->
              </div>
              <h6 class="title clamp1">
                {{ item.ItemName }}
              </h6>
              <div class="bot flex flex-pack-justify">
                <div class="fz ellipsis">
                  <i class="iconfont yt-jiangpai"></i>{{ item.SF1 }}
                </div>
                <div class="fz ellipsis">
                  <i class="iconfont yt-time"></i>{{ item.SF2 }}
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="idx-s4">
      <div class="auto wrap flex flex-pack-justify flex-w">
        <div class="idx-zx">
          <div class="idx-tt">
            <span class="bt">工程咨询</span>
            <router-link to="/gongchengzixun" class="more fr"
              ><i class="iconfont yt-more"></i
            ></router-link>
          </div>
          <ul class="list">
            <li
              class="item"
              v-for="(item, index) in list8"
              :key="index"
              v-if="index < 4"
            >
              <router-link
                :to="{
                  path: '/gongchengzixun',
                  query: {
                    itemId: item.ItemId,
                    rootId: rootIdNum,
                    supItemId: item.ParentId,
                    typeCode: item.TypeCode,
                  },
                }"
                class="title ellipsis"
                >{{ item.ItemName }}</router-link
              >
              <div class="date">
                <span>{{
                  item.SF5
                    ? common.dateFormat("YYYY-mm-dd", item.SF5)
                    : common.dateFormat("YYYY-mm-dd", item.CrDate)
                }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="idx-rc">
          <div class="idx-tt">
            <span class="bt">人才培养</span>
            <router-link to="/rencaipeiyang" class="more fr"
              ><i class="iconfont yt-more"></i
            ></router-link>
          </div>
          <ul class="list">
            <li
              class="item"
              v-for="(item, index) in list9"
              v-if="index < 4"
              :key="index"
            >
              <router-link
                :to="{
                  path: '/rencaipeiyang',
                  query: {
                    itemId: item.ItemId,
                    rootId: rootIdNum,
                    supItemId: item.ParentId,
                    typeCode: item.TypeCode,
                  },
                }"
                class="flex"
              >
                <div class="imgbox zoomimg">
                  <img
                    :src="
                      item.Thum == null || item.Thum[0] == undefined
                        ? naDefault
                        : apiUrl +
                          '/Item/PreviewFile?fileName=' +
                          encodeURIComponent(item.Thum[0])
                    "
                    class="img"
                  />
                </div>
                <div class="info">
                  <h6 class="title ellipsis">{{ item.ItemName }}</h6>
                  <p class="date" v-if="item.SF2">性别：{{ item.SF2 }}</p>
                  <p class="date" v-if="item.SF3">出生：{{ item.SF3 }}</p>
                  <p class="date" v-if="item.SF9">
                    {{ item.SF9 }}<span v-if="item.SF8">({{ item.SF8 }})</span>
                  </p>
                  <em class="arrow"></em>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <foot />
  </div>
</template>

<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";
import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
import axios from "axios";

Swiper.use([Pagination, Navigation, Autoplay]);

export default {
  name: "",
  data() {
    return {
      swiperLists: [
        {
          img: require("../../assets/images/banner01.jpg"),
          url: "/hexinjishu?typeCode=CX027",
        },
        {
          img: require("../../assets/images/banner02.jpg"),
          url: "/hexinjishu?typeCode=CX029",
        },
        {
          img: require("../../assets/images/banner03.jpg"),
          url: "/hexinjishu?typeCode=CX031",
        },
        {
          img: require("../../assets/images/banner04.jpg"),
          url: "/hexinjishu?typeCode=CX030",
        },
      ],
      banner: require("../../assets/images/idx_01.jpg"),
      tmpImg: require("../../assets/images/idx_02.jpg"),
      kjjlImg: [
        require("../../assets/images/kejijiangli1.jpg"),
        require("../../assets/images/kejijiangli2.jpg"),
        require("../../assets/images/kejijiangli3.jpg"),
      ],
      naAvatar: [
        require("../../assets/images/society_03_01.jpg"),
        require("../../assets/images/society_03_02.jpg"),
        require("../../assets/images/society_03_03.jpg"),
        require("../../assets/images/society_03_04.jpg"),
      ],
      naDefault: require("../../assets/images/na_default.png"),

      rootIdNum: "10000",
      itemIdNum: "16",
      typeCodeNum: "CX005",
      //默认第一页
      currentPage: 1,
      //数据总条数
      dataCount: 0,
      pageSize: 10,
      //学术成果轮播数据
      kjcgList1: [],
      //学术成果右边数据
      kjcgList2: [],
      //学术成果底部数据
      kjcgList3: [],
      list4: [],
      list5: [],
      list6: [],
      list7: [],
      list8: [],
      list9: [],
    };
  },
  components: {
    headNav,
    foot,
  },
  mounted() {
    new Swiper(".idx-ban .swiper-container", {
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    new Swiper(".idx-cg .swiper-container", {
      loop: true,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  created() {
    this.InitList();
  },
  methods: {
    send(
      rootIdNum,
      itemIdNum,
      typeCodeNum,
      currentPage,
      callback,
      keyWord = null,
      DisableItemContent = true
    ) {
      let that = this;
      if (rootIdNum == "" && itemIdNum == "" && typeCodeNum == "") {
        rootIdNum = that.rootIdNum;
        itemIdNum = that.itemIdNum;
        typeCodeNum = that.typeCodeNum;
      }
      var obj = {
        rootId: rootIdNum,
        itemId: itemIdNum,
        typeCode: typeCodeNum,
        pageIndex: currentPage,
        pageSize: that.pageSize,
        keyWord: keyWord,
        itemOrdey: true,
        DisableItemContent: DisableItemContent,
      };
      //   var obj = { rootId: '10000', itemId: '120', typeCode: 'CX032' };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/Source/Item",
        data: centent,
        success: function (res) {
          that.dataCount = res.Count;
          if (callback) callback(res);
        },
      });
    },
    InitList() {
      //初始化kjcgList1
      let that = this;
      //初始化kjcgList2
      this.kjcgList2 = JSON.parse(sessionStorage.getItem("kjcgList2"));
      if (!this.kjcgList2) {
        this.send(this.rootIdNum, 17, "CX006", 0, (res) => {
          {
            this.kjcgList2 = res.Data.Items;
            sessionStorage.setItem("kjcgList2", JSON.stringify(res.Data.Items));
          }
        });
      }

      //初始化kjcgList3
      this.kjcgList3 = JSON.parse(sessionStorage.getItem("kjcgList3"));
      if (!this.kjcgList3) {
        this.send(this.rootIdNum, 19, "CX008", 0, (res) => {
          {
            this.kjcgList3 = res.Data.Items;
            sessionStorage.setItem("kjcgList3", JSON.stringify(res.Data.Items));
          }
        });
      }

      //初始化List4
      this.list4 = JSON.parse(sessionStorage.getItem("list4"));
      if (!this.list4) {
        this.send(this.rootIdNum, 115, "CX027", 0, (res) => {
          {
            this.list4 = res.Data.Items;
            sessionStorage.setItem("list4", JSON.stringify(res.Data.Items));
          }
        });
      }

      //初始化list5
      this.list5 = JSON.parse(sessionStorage.getItem("list5"));
      if (!this.list5) {
        this.send(
          this.rootIdNum,
          31,
          "CX020",
          0,
          (res) => {
            {
              this.list5 = res.Data.Items;
              sessionStorage.setItem("list5", JSON.stringify(res.Data.Items));
            }
          }
          // null,
          // false
        );
      }

      //初始化list6
      this.list6 = JSON.parse(sessionStorage.getItem("list6"));
      if (!this.list6) {
        this.send(
          this.rootIdNum,
          32,
          "CX021",
          0,
          (res) => {
            {
              this.list6 = res.Data.Items;
              sessionStorage.setItem("list6", JSON.stringify(res.Data.Items));
            }
          }
          // null,
          // false
        );
      }

      //初始化list7
      this.list7 = JSON.parse(sessionStorage.getItem("list7"));
      if (!this.list7) {
        this.send(
          this.rootIdNum,
          23,
          "CX012",
          0,
          (res) => {
            {
              this.list7 = res.Data.Items;
              sessionStorage.setItem("list7", JSON.stringify(res.Data.Items));
            }
          },
          '{"SF6":"是"}'
        );
      }
      //初始化list8
      this.send(this.rootIdNum, 26, "CX015", 0, (res) => {
        {
          this.list8 = res.Data.Items;
        }
      });
      //初始化list9
      this.send(this.rootIdNum, 27, "CX016", 0, (res) => {
        {
          console.log(res.Data.Items, "res.Data.Items");
          this.list9 = res.Data.Items;
        }
      });
      // if (this.list9.length == 0) {
      //   this.send(this.rootIdNum, 27, "CX016", 0, (res) => {
      //     {
      //       for (var index in res.Data.Items) {
      //         if (index == 0) {
      //           that.list9.push(res.Data.Items[0]);
      //         }
      //       }
      //     }
      //   });
      //   this.send(this.rootIdNum, 28, "CX017", 0, (res) => {
      //     {
      //       for (var index in res.Data.Items) {
      //         if (index == 0) {
      //           that.list9.push(res.Data.Items[0]);
      //         }
      //       }
      //     }
      //   });
      //   this.send(this.rootIdNum, 29, "CX018", 0, (res) => {
      //     {
      //       for (var index in res.Data.Items) {
      //         if (index == 0) {
      //           that.list9.push(res.Data.Items[0]);
      //         }
      //       }
      //     }
      //   });
      //   this.send(this.rootIdNum, 30, "CX019", 0, (res) => {
      //     {
      //       for (var index in res.Data.Items) {
      //         if (index == 0) {
      //           that.list9.push(res.Data.Items[0]);
      //         }
      //       }
      //     }
      //   });
      // }
    },

    delHtmlTag(str) {
      return str.replace(/<[^>]+>/g, "");
    },
  },
  computed: {
    // sortArray: function () {
    //   var key = "TypeCode";
    //   return this.list9.sort(function (a, b) {
    //     var x = a[key];
    //     var y = b[key];
    //     return x < y ? -1 : x < y ? 1 : 0;
    //   });
    // },
  },
};
</script>

<style scoped></style>
